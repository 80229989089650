import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as loginActions from '../actions/loginActions';
import * as reviewsActions from '../actions/reviewsActions';
import Text from "../components/utils/Text";
import Icon from "../components/utils/Icon";
import ReviewRow from "../components/Reviews/ReviewRow";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from '../components/utils/Button';
import { Api } from '../resources/Api';
import moment from 'moment';

class Reviews extends Component {

	onApproveReview = async (id, entityId, entityType) => {
		const {approveReview, getReviews} = this.props.reviewsActions;
		console.log('Reviews: ', id, entityId, entityType)
		await approveReview(id, entityId, entityType);
		//getReviews(id, restId);

	};

	onDeclineReview = async(id, restId) => {
		const {declineReview, getReviews} = this.props.reviewsActions;
		await declineReview(id, restId);
		//getReviews(id, restId);
	};

	componentDidMount() {
		setTimeout(() => {
			if (!this.props.login.loggedIn) {
				this.props.history.push('/');
			}
		}, 500);
	}
	
	exportToExcel = async () => {
		const result = await Api.reviewsListGraphQL();
		if (result.result === "success" || result.result === "warning") {
		  console.log("Fetched reviews data:", result.data);
	  
		  if (Array.isArray(result.data.reviews.data)) {
			const reviews = result.data.reviews.data.map((review) => {
				const app_user_data = review?.attributes?.app_user?.data;
					  
			  return {
				id: review?.id,
				description: decodeURI(review?.attributes?.description),
				score: review?.attributes?.score,
				created: review?.attributes?.created,
				pics: review?.attributes?.pics?.data?.map((pic) => pic.attributes.url).join(", "), // need to test
				restaurant_id: review?.attributes?.restaurant?.data?.id,
				restaurant_name: review?.attributes?.restaurant?.data?.attributes?.restaurant_name,
				hash_tags: review?.attributes?.hash_tags.data.map((tag) => tag.attributes.name).join(", "),
				reviewer_name: decodeURI(review?.attributes?.reviewer_name),
				pics: review?.attributes?.pics?.data?.map((pic) => pic.attributes.url).join(", "),
				// ****** from app_user: ******
				app_user_id: app_user_data?.id,
				installation_id: app_user_data?.attributes?.installation_id,
				email: app_user_data?.attributes?.email,
				first_name: app_user_data?.attributes?.first_name,
				last_name: app_user_data?.attributes?.last_name,
				gender: app_user_data?.attributes?.gender,
				phone_number: app_user_data?.attributes?.phone_number,
				app_user_number_of_reviews: app_user_data?.attributes?.reviews?.data?.length
			  };
			});
	  
			const ws = XLSX.utils.json_to_sheet(reviews);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], {
			  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
			});
			saveAs(data, "reviews-" + moment().format('YYYY-MM-DD') + ".xlsx");
		  } 
		  else {
			alert("Nothing to export");
		  }
		}
		
		else {
		  console.error("Failed to fetch reviews");
		  alert("Failed to export reviews");
		  if (Array.isArray(result.errors)) {
			result.errors.forEach((error) => {
			  console.error(error.message);
			  alert(error.message);
			});
		  }  			
		}
	}
			
	  
	render() {

		const {reviews, login} = this.props;
		const {restaurants} = this.props.menus;
		const pendingReviews = reviews.pendingReviews.sort((a,b) => (a.id*1<b.id*1)?1:-1);

		return (
			<div>
				{login.loggedIn &&
				<div className="card-deck mt-3">
					<div className="card">
						<div className="card-body">
							<h5 className="card-title text-secondary"><Text>sections.reviews</Text></h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item list-group-item-light">{reviews.pendingReviews.length>0 && <Icon className="text-warning mx-2">exclamation-triangle</Icon>}
								<span className="font-weight-bold text-dark"> <Text num={pendingReviews.length}>reviews.numPendingReviews</Text></span>
							</li>
						</ul>
						{pendingReviews.map(review => {
							//const restaurant = restaurants.find(rest => rest.id === review.restaurant_id);
							return (
								<ReviewRow key={review.id} review={review} restaurant={review.entity_name}
										   onApproveReview={() => this.onApproveReview(review.id, review.entity_id, review.entity_type)}
										   onDeclineReview={() => this.onDeclineReview(review.id, review.entity_id, review.entity_type)} />
							)
						}
						)}
					</div>

				</div>}
				<Button success={1} onClick={this.exportToExcel}>Export ALL Reviews to Excel</Button>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { login: state.login, menus: state.menus, reviews: state.reviews }
};

const mapDispatchToProps = dispatch => {
	return {
		loginActions: bindActionCreators({...loginActions}, dispatch),
		reviewsActions: bindActionCreators({...reviewsActions}, dispatch),
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Reviews);