import {
	Box,
	Button,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, {useEffect, useReducer, useState} from 'react';
import {Api} from '../resources/Api';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
const PHOTOS = 'photos';

export default function SuggestedEntities() {
	const [suggestedEntities, setSuggestedEntities] = useState([]);
	const [filteredList, setFilteredList] = useState([]);
	const [status, setStatus] = useState([]);
	const [country, setCountry] = useState('IL');
	const [entityType, setEntityType] = useState('restaurant');
	const [suggestionType, setSuggestionType] = useState('both');
	const [showApprovalButton, setShowApprovalButton] = useState(false);
	const [rowId, setRowId] = useState(null);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);

	useEffect(() => {
		const fetchData = async () => {
			const res = await Api.getAllSuggestedEntitiesByCountry(country);
			console.log('res:   ', res);
			return res;
		};
		fetchData().then((res) => {
			setSuggestedEntities(res.data);
			setFilteredList(res.data);
			let statusList = [];
			res.data.map((edit) => {
				statusList.push({id: edit.id, status: edit.edit_status || 'new'});
			});
			setStatus(statusList);
		});
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			const res = await Api.getAllSuggestedEntitiesByCountry(country);
			console.log('res:   ', res);
			return res;
		};
		fetchData().then((res) => {
			setSuggestedEntities(res.data);
			setFilteredList(res.data);

			let statusList = [];
			res.data.map((edit) => {
				statusList.push({id: edit.id, status: edit.edit_status || 'new'});
			});
			setStatus(statusList);
			setEntityType('restaurant');
		});
	}, [country]);
	useEffect(() => {
		console.log('about to filter by type', entityType);
		setFilteredList(
		  suggestedEntities.filter((entity) => {
			if (suggestionType === PHOTOS) {
			  return entity.entity_type === entityType && entity.pics && entity.pics.length > 0;
			}
			return (
			  entity.entity_type === entityType &&
			  (suggestionType === 'new' ? !entity.entity_id : !!entity.entity_id || suggestionType === 'both')
			);
		  })
		);
	  }, [entityType, suggestionType]);

	async function onChangeStatus(e, id) {
		if (e.target.value === 'done') {
			setRowId(id);
			setShowApprovalButton(true);
		} else {
			console.log('val:    ', e.target.value);
			let statusList = status;
			statusList.map((s) => {
				if (s.id === id) s.status = e.target.value;
			});
			setStatus(statusList);
			forceUpdate();
			const entity = suggestedEntities.find((entity) => entity.id === id);
			const res = await Api.updateSuggestedEntityStatus(
				e.target.value,
				id,
				entity.country,
				entity.user_email,
				entity.entity_type,
				entity.user_name
			);
			console.log('res: 			', res);
		}
	}

	async function onApproveClick() {
		let statusList = status;
		statusList.map((s) => {
			if (s.id === rowId) s.status = 'done';
		});
		setStatus(statusList);
		forceUpdate();
		const entity = suggestedEntities.find((entity) => entity.id === rowId);
		const res = await Api.updateSuggestedEntityStatus(
			'done',
			rowId,
			entity.country,
			entity.user_email,
			entity.entity_type,
			entity.user_name
		);
		console.log('res: 			', res);
		onCancelClick();
	}
	function onCancelClick() {
		setShowApprovalButton(false);
		setRowId(null);
	}
	function onChangeFilter(e, filterType) {
		console.log('val:    ', e.target.value);
		switch (filterType) {
			case 'country':
				setCountry(e.target.value);

				break;
			case 'entityType':
				setEntityType(e.target.value);

				break;
			case 'suggestionType':
				setSuggestionType(e.target.value);

				break;

			default:
				break;
		}
	}

	const exportToExcel = async () => {
		try {
		  if (filteredList.length > 0) {
			const entities = filteredList.map((entity) => {
			  if (suggestionType === PHOTOS) {
				const photoUrls = entity.pics && entity.pics.length > 0
				  ? entity.pics.reduce((acc, pic, index) => {
					  acc[`url-${index + 1}`] = pic.url;
					  return acc;
					}, {})
				  : { "url-1": "No photos available" };
	  
				return {
				  entity_type: entity.entity_type,
				  entity_id: entity.entity_id,
				  ...photoUrls,
				};
			  } else {
				return {
				  id: entity.id,
				  entity_type: entity.entity_type,
				  name: entity.name,
				  address: entity.address,
				  lon: entity.location?.lon,
				  lat: entity.location?.lat,
				  country: entity.country,
				  website_url: entity.website_url,
				  user_email: entity.user_email,
				  status: entity.edit_status,
				  created_at: entity.createdAt,
				  ...(entityType === PHOTOS && {
					entity_type: entity.entity_type,
					entity_name: entity.name,
					entity_id: entity.entity_id,
					url: entity.pics && entity.pics.length > 0 ? entity.pics.map(pic => pic.url).join(', ') : 'No photos available',
				  }),
				};
			  }
			});
	  
			const ws = XLSX.utils.json_to_sheet(entities);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], {
			  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
			});
	  
			const formattedDate = moment().format("YYYY-MM-DD");
			saveAs(data, `suggested-entities-${formattedDate}.xlsx`);
		  } else {
			alert("Nothing to export");
		  }
		} catch (error) {
		  console.error("Error exporting to Excel:", error);
		  alert("An error occurred while exporting to Excel. Please try again.");
		}
	  };

function renderNonPhotosView(row, status, onChangeStatus) {
	return (
		<>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px'}}
			>
				{row.entity_type}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				style={{
					minWidth: '300px',
					backgroundColor: 'aliceblue',
					whiteSpace: 'pre-line',
				}}
				align="center"
			>
				{row.name}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				style={{minWidth: '300px'}}
				align="center"
			>
				{row.address}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				style={{
					minWidth: '300px',
					backgroundColor: 'aliceblue',
					whiteSpace: 'pre-line',
				}}
				align="center"
			>
				{`lon: ${row.location?.lon} lat: ${row.location?.lat}` || 'no location'}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px'}}
			>
				{row.country}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px', backgroundColor: 'aliceblue'}}
			>
				{row.website_url}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				style={{minWidth: '300px'}}
				align="center"
			>
				{row.user_email || ''}
			</TableCell>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px', backgroundColor: 'aliceblue'}}
			>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={status.find((s) => s.id === row.id).status}
					autoWidth
					onChange={(e) => {
						onChangeStatus(e, row.id);
					}}
					style={{minWidth: '100px'}}
				>
					<MenuItem value={'new'}>new</MenuItem>
					<MenuItem value={'work'}>work</MenuItem>
					<MenuItem value={'done'}>done</MenuItem>
				</Select>
			</TableCell>
			<TableCell 
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px'}}>  
				{row.createdAt || ''}
			</TableCell>
		</>
	);
}

function renderPhotosView(row) {
	return (
		<>
			<TableCell
				sx={{borderRight: '1px solid gray'}}
				align="center"
				style={{minWidth: '300px'}}
			>
				{row.entity_id}
			</TableCell>
			{row.pics && row.pics.length > 0 ? (
				row.pics.map((pic, index) => (
					<TableCell
						key={index}
						sx={{borderRight: '1px solid gray'}}
						align="center"
						style={{minWidth: '300px', backgroundColor: 'aliceblue'}}
					>
						<img src={pic.url} alt={`photo-${index + 1}`} style={{maxWidth: '100px', maxHeight: '100px', marginRight: '5px'}} />
					</TableCell>
				))
			) : (
				<TableCell
					sx={{borderRight: '1px solid gray'}}
					align="center"
					style={{minWidth: '300px', backgroundColor: 'aliceblue'}}
				>
					No photos available
				</TableCell>
			)}
			<TableCell
				sx={{ borderRight: '1px solid gray' }}
				align="center"
				style={{ minWidth: '300px' }}
			>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={status.find((s) => s.id === row.id).status}
					autoWidth
					onChange={(e) => {
						onChangeStatus(e, row.id);
					}}
					style={{minWidth: '100px'}}
				>
					<MenuItem value={'new'}>new</MenuItem>
					<MenuItem value={'work'}>work</MenuItem>
					<MenuItem value={'done'}>done</MenuItem>
				</Select>
		</TableCell>
			
		</>
	);
}

function BasicTable() {
	const isPhotoSuggestion = entityType == PHOTOS || suggestionType == PHOTOS;
	return (
		<TableContainer component={Paper} style={{maxHeight: '80vh'}}>
			<Table sx={{minWidth: '100%'}} aria-label="sticky table" stickyHeader>
				<TableHead>
					<TableRow hover style={{backgroundColor: 'teal'}}>
						<TableCell style={{fontWeight: 'bold'}}>ID</TableCell>
							{!isPhotoSuggestion ? (
							<>
								<TableCell style={{fontWeight: 'bold'}}>type</TableCell>
								<TableCell align="center" style={{fontWeight: 'bold'}}>Name</TableCell>
								<TableCell align="center" style={{fontWeight: 'bold'}}>Address</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">Location</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">Country</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">URL</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">User email</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">Status</TableCell>
								<TableCell style={{fontWeight: 'bold'}} align="center">Created At</TableCell>
							</>
						) : (
							<>
								<TableCell style={{fontWeight: 'bold'}} align="center">Entity ID</TableCell>
								{filteredList.length > 0 && filteredList[0].pics && filteredList[0].pics.length > 0 && 
									filteredList[0].pics.map((_, index) => (
										<TableCell key={index} style={{fontWeight: 'bold'}} align="center">Photo {index + 1}</TableCell>
									))
								}
								<TableCell style={{fontWeight: 'bold'}} align="center">Status</TableCell>

							</>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredList.map((row) => {
						let rowStatus = status.find((s) => s.id === row.id).status;
						return (
							<TableRow hover key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
								<TableCell
									component="th"
									scope="row"
									style={{
										backgroundColor:
											rowStatus === 'new'
												? 'indianred'
												: rowStatus === 'work'
												? 'lightyellow'
												: 'lightgreen',
										position: 'sticky',
										left: 0,
										boxShadow: '5px 2px 5px grey',
										borderRight: '2px solid black',
									}}
								>
									{row.id}
								</TableCell>
									{!isPhotoSuggestion
									? renderNonPhotosView(row, status, onChangeStatus)
									: renderPhotosView(row)}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

	return (
		<div>
			<h1>SuggestedEntities</h1>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={country}
						autoWidth
						onChange={(e) => {
							onChangeFilter(e, 'country');
						}}
						style={{minWidth: '100px'}}
					>
						<MenuItem value={'IL'}>IL</MenuItem>
						<MenuItem value={'GB'}>GB</MenuItem>
						<MenuItem value={'US'}>US</MenuItem>
					</Select>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<InputLabel id="demo-simple-select-standard-label">Type of entity</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={entityType}
						autoWidth
						onChange={(e) => {
							onChangeFilter(e, 'entityType');
						}}
						style={{minWidth: '100px'}}
					>
						<MenuItem value={'restaurant'}>restaurant</MenuItem>
						<MenuItem value={'business'}>business</MenuItem>
						<MenuItem value={'product'}>product</MenuItem>
					</Select>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<InputLabel id="demo-simple-select-standard-label">Type of suggestion</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={suggestionType}
						autoWidth
						onChange={(e) => {
							onChangeFilter(e, 'suggestionType');
						}}
						style={{minWidth: '100px'}}
					>
						<MenuItem value={'edit'}>edit</MenuItem>
						<MenuItem value={'new'}>new</MenuItem>
						<MenuItem value={'both'}>both</MenuItem>
						<MenuItem value={'photos'}>photos</MenuItem>
					</Select>
				</div>
			</div>
			<Button variant="contained" onClick={exportToExcel}>Export to Excel</Button>
			<Modal
				open={showApprovalButton}
				onClose={null}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography align="center" id="modal-modal-title" variant="h6" component="h2">
						You're about to approve this suggestion and send the user an email
					</Typography>
					<Typography align="center" id="modal-modal-description" sx={{mt: 5}}>
						are you approving such a thing?
					</Typography>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-evenly',
							marginTop: 20,
						}}
					>
						<Button
							onClick={() => {
								onApproveClick();
							}}
							variant="contained"
						>
							APPROVE
						</Button>
						<Button
							onClick={() => {
								onCancelClick();
							}}
							variant="contained"
						>
							CANCEL
						</Button>
					</div>
				</Box>
			</Modal>

			{filteredList && filteredList.length > 0 && status && status.length > 0 && BasicTable()}
		</div>
	);
}
